<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('account.username')" prop="username">
          <el-input
            maxlength="30"
            show-word-limit
            v-model="ruleForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('account.role')" prop="role">
          <el-select v-model="ruleForm.role" :placeholder="$t('please_select')">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="!id" :label="$t('account.password')" prop="password">
          <el-input
            maxlength="30"
            show-word-limit
            v-model="ruleForm.password"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import { accountCreate, accountDetail, accountEdit } from "@/api/index";
export default {
  name: "accountEdit",
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
        role:1
      },
       options:[
          {
              label:this.$t('account.role1'),
              value:1
          },
          {
              label:this.$t('account.role2'),
              value:2
          },
          {
              label:this.$t('account.role3'),
              value:3
          }
      ],
      rules: {
        username: [
          {
            required: true,
            message: this.$t("account").username,
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: this.$t("account").role,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("account").password,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.rules.password[0].required = false
    this.get_info();
  },
  methods: {
    async get_info() {
      const information = await accountDetail(this.id);
      this.ruleForm = { ...information };
    },
    async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.id === undefined) {
        await accountCreate(this.ruleForm);
      } else {
        await accountEdit({id:this.id,...this.ruleForm});
      }
      this.$router.go(-1);
    },
  },
};
</script>